*[dir="rtl"] .metismenu .arrow {
    float: left;
}
*[dir="rtl"] .metismenu .plus-times {
    float: left;
}
*[dir="rtl"] .metismenu .plus-minus {
    float: left;
}

.metismenu{
    .arrow {
        float: right;
        line-height: 1.42857;
    }
}

.metismenu .glyphicon.arrow:before {
    content: "\e079";
}
.metismenu .active > a > .glyphicon.arrow:before {
    content: "\e114";
}
.metismenu .fa.arrow:before {
    content: "\f104";
}
.metismenu .active > a > .fa.arrow:before {
    content: "\f107";
}


.metismenu .ion.arrow:before {
    content: "\f3d2"
}
.metismenu .active > a > .ion.arrow:before {
    content: "\f3d0";
}
.metismenu .plus-times {
    float: right;
}

.metismenu .fa.plus-times:before {
    content: "\f067";
}
.metismenu .active > a > .fa.plus-times {
    transform: rotate(45deg);
}
.metismenu .plus-minus {
    float: right;
}

.metismenu .fa.plus-minus:before {
    content: "\f067";
}
.metismenu .active > a > .fa.plus-minus:before {
    content: "\f068";
}

.metismenu{
    .collapse {
        display: none;
        &.in{
            display: block;
        }
    }
    .collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        transition-timing-function: ease;
        transition-duration: .35s;
        transition-property: height, visibility;
    }
    .has-arrow {
        position: relative;
        &:after {
            position: absolute;
            content: '';
            width: .5em;
            height: .5em;
            border-width: 1px 0 0 1px;
            border-style: solid;
            border-color: initial;
            right: 1em;
            transform: rotate(-45deg) translate(0, -50%);
            transform-origin: top;
            top: 50%;
            transition: all .3s ease-out;
        }
    }
}

*[dir="rtl"] .metismenu .has-arrow::after {
    right: auto;
    left: 1em;
    transform: rotate(135deg) translate(0, -50%);
}

.metismenu .active > .has-arrow::after,
.metismenu .has-arrow[aria-expanded="true"]::after {
    transform: rotate(-135deg) translate(0, -50%);
}

*[dir="rtl"] .metismenu .active > .has-arrow::after,
*[dir="rtl"] .metismenu .has-arrow[aria-expanded="true"]::after {
    transform: rotate(225deg) translate(0, -50%);
}
