/* Elements */
.metric-inline {
	@include border-radius(3px);
	@extend .align-center;
	padding: 20px;
	font-size: 36px;
	background-color: #f5f5f5;

	i {
		@extend .displayblock;
		margin-bottom: 12px;
		color: $color-muted;
	}

	span {
		@extend .displayblock;
		font-size: 20px;
	}
}
.separator-linethrough {
	position: relative;
	margin: 30px 0;
	@extend .align-center;

	span {
		@include inline-block;

		padding: 0 10px;
		position: inherit;
		font-size: 18px;
		background-color: $col-white;
		z-index: 9;
	}

	&:after {
		@extend .displayblock;
		position: absolute;
		top: 50%;
		width: 100%;
		content: '';
		border-top: 1px solid #f0f0f0;
		z-index: 0;
	}
}
.block-header{
    @extend .m-t-20;
    @extend .m-b-20;
	h2{
		font-size: 22px;
	}
	.breadcrumb{
        @extend .margin-0;
        @extend .padding-0;
        background: transparent;
    }
}
/* toastr notification */
#toast-container {
	> div {
		@include opacity(1);

		&,
		&:hover{
			@include box-shadow(none);
		}
    }

    .toast-close-button {
        @include opacity(.3);

        top: -11px;
        text-shadow: none;
        color: $col-white;

        &:hover,
        &:focus {
            @include opacity(.5);
        }
    }

    .toast-info {
        background-color: $color-accent;

        .toast-close-button {
            color: darken($color-accent, 20%);
        }
        .toast-body{
            width: 100%;
        }
    }

    .toast-success {
        background-color: $color-success;

        .toast-close-button {
            color: darken($color-success, 20%);
        }
    }

    .toast-warning {
        background-color: $color-warning;

        .toast-close-button {
            color: darken($color-warning, 20%);
        }
    }

    .toast-error {
        background-color: $color-danger;

        .toast-close-button {
            color: darken($color-danger, 25%);
        }
    }

    .toast {
        @extend .m-b-0;
        a {
            text-decoration: underline;

            &:hover,
            &:focus {
                text-decoration: none;
                color: $col-white;
            }
        }

        .toast-message {
            font-size: 14px;
            line-height: 1.4;
        }
    }
}


.select2-container{
    .select2-choice{
        border: none;
        background-image: none;
        box-shadow: none;
        padding: 0;
        margin: 0;
        &:focus,
        &:hover,
        &:active{
            box-shadow: none !important;
            border: 0 !important;
        }
        .select2-arrow{
            border-left:none;
            background-image: none;
            background: transparent;
        }
    }
    &.select2-dropdown-open{
        .select2-choice{
            background: transparent;
            box-shadow: none !important;
        }
    }
}
.select2-drop{
    border-radius: 0;
    border: 0 !important;
}
.select2-container-multi{
    .select2-choices{
        border: 0 !important;
        padding: 0;
        margin: 0;
        background-image: none;
        box-shadow: none !important;
        &:focus,
        &:hover,
        &:active{
            box-shadow: none !important;
            border: none;
            border: 0 !important;
        }
        .select2-search-field{
            input{
                padding: 0;
            }
        }
    }
}
