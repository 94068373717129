.btn-green {
    color: #fff;
    background-color: #29235c;
    border-color: #29235c;
}

.btn-green:hover, .btn-green:active,  .btn-green:focus{
    color: #fff;
    background-color: #2ea093;
    border-color: #2ea093;
}
.list-group-item {
    border: 1px solid #eee;
}
.plus-minus .form-control{width: 50px; text-align: center;}

.tag-wrapper.ReactTags__tag{display: inline-flex; align-items: center; background-color: #29235c;color: #fff; padding: 2px 15px; border-radius: 8px; margin-bottom: 5px;
button{
    background-color:transparent;
    border: 0;
    color:#fff;
    padding-right: 0;
    font-size: 18px;
}
}
.nav-tabs-new2 > li{margin-bottom: 8px;}
.nav-tabs-new2 > li.red > a{
background-color: #fceff0;
&.active {
    border-bottom: 2px solid #bd1622 !important;
    background-color: #fceff0;
    color: #bd1622;
}
}

.rdw-editor-main {
    border: 1px solid #ced4da;
    padding: 0px 10px;
    border-radius: 0.25rem;
}